@primary-color: #1a110d; // primary color for all components
@layout-body-background: #ffffff;
@component-background: #ffffff;

@font-face {
    font-family: "Louis Vuitton Regular";
    src: local("Louis Vuitton Regular"),
      url(./fonts/LouisVuitton-Regular.woff2) format("woff2");
      font-weight: normal;

    font-family: "Louis Vuitton Bold";
    src: local("Louis Vuitton Bold"),
      url(./fonts/LouisVuitton-Bold.woff2) format("woff2");
      font-weight: bold;

    font-family: "Louis Vuitton Light";
    
    src: local("Louis Vuitton Light"),
      url(./fonts/LouisVuitton-Light.woff2) format("woff2");
      font-weight: normal;
  }

  body{
      font-family: 'Louis Vuitton Light', 'Louis Vuitton Bold';
  }

  .bold {
    // font-family: "Louis Vuitton Bold", "Helvetica Neue", "Helvetica", Arial,
    //   sans-serif;
    font-weight: bold;
  }
  
  .regular {
    // font-family: "Louis Vuitton Regular", "Helvetica Neue", "Helvetica", Arial,
    //   sans-serif;
  }
  
  .light {
    // font-family: "Louis Vuitton light", "Helvetica Neue", "Helvetica", Arial,
    //   sans-serif;
  }
@primary-color: #1a110d;