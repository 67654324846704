@media (min-width: 320px) {
  /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  .player-config {
    height: 45vh !important;
  }
  .player {
    height: 80vh !important;
  }
}

@media (min-width: 600px) {
  /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  .player-config {
    height: 55vh !important;
  }
  .player {
    height: 80vh !important;
  }
}
@media (min-width: 801px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
  .player-config {
    height: 50vh !important;
  }
  .player {
    height: 80vh !important;
  }

}
@media (min-width: 1025px) {
  /* big landscape tablets, laptops, and desktops */
  .player-config {
    height: 45vh !important;
  }
  .player {
    height: 60vh !important;
  }
}
@media (min-width: 1281px) {
  /* hi-res laptops and desktops */
  .player-config {
    height: 45vh !important;
  }
  .player {
    height: 60vh !important;
  }
}
