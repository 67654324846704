/* @import '~antd/dist/antd.css'; */
/* @import '~antd/dist/antd.less'; */

/* @font-face {
  font-family: "Louis Vuitton Regular";
  src: local("Louis Vuitton Regular"),
    url(./fonts/LouisVuitton-Regular.woff2) format("woff");
  font-family: "Louis Vuitton Bold";
  src: local("Louis Vuitton Bold"),
    url(./fonts/LouisVuitton-Bold.woff2) format("woff");
  font-family: "Louis Vuitton Light";
  src: local("Louis Vuitton Light"),
    url(./fonts/LouisVuitton-Light.woff2) format("woff");
} */

body {
  height: 100vh;
  overflow: hidden;

}



#header {
  background: #ffffff;
  background-repeat: no-repeat;
  background-size: auto 30px;
  background-position-y: center;
  background-position-x: 30px;
  height: 8vh;
  /* background-image: url(/images/logo.svg); */
  box-shadow: 0 1px 0 0 #eae8e4;
  max-height: 75px;
  color: #ffffff;
  padding: 10px;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 4%), 0 12px 20px 0 rgb(0 0 0 / 8%);
}
@media screen and (orientation: portrait) {
  #player {
    /* height: 63vh;
    width: 100vw; */
  }
}
@media screen and (orientation: landscape) {
  #player {
    height: 60vh;
    width: 100vw;
  }
}

.btn-container {
  /* height: 25vh */
  margin: 0 10px 0 10px;
}
.attribute-list {
  display: flex;
  flex-wrap: nowrap;
}

.layout {
  background-color: #ffffff !important;
}
.ant-tabs-nav-wrap {
  display: flex;
  justify-content: center;
}

.rec.rec-arrow {
  background-color: #1a110d;
  color: white;
  display: none;
}

/* hide disabled buttons */
.rec.rec-arrow:disabled {
  background-color: #eae8e4;
  color: #595857;
}

.rec.rec-arrow:hover {
  border-radius: 50%;
}

.rec.rec-arrow:hover:enabled {
  background-color: #1a110d;
  color: white;
}

.rec.rec-dot_active {
  background-color: #eae8e4;
  box-shadow: 0 0 1px 3px #1a110d;
}

ul.attr-list {
  padding-left: 0;
}
ul.attr-list li {
  display: inline;
  list-style: none;
  border-radius: 2px;
  border: 1px solid #cccbc8;
  margin: 3px;
  padding: 3px;
  cursor: pointer;
  background-color: #1a110d;
  color: #f8f8f8;
  font-weight: bold;
  /* box-shadow: 0 4px 8px 0 rgb(0 0 0 / 4%), 0 12px 20px 0 rgb(0 0 0 / 8%); */
}

.auth{
  align-items: center;
  align-content: center;
  text-align: center;
}
.auth-el{
  margin: 5px;
}

.cam-control {
  /* float: right; */
  /* align-content: center; */
  position: relative;
  /* margin: auto; */
  text-align: center;
}
#cam-list {
  width: 100vw;
  /* height: 20px; */
}
.attribute-list {
  width: 100%;
  margin: auto;
}
