.CustomTriggerCSS {
  background-color: lightcoral;
  transition: background-color 200ms ease;
}
.form-step-title{
}
.form-container{
    display: flex;
}
.item{
    flex-grow: 4;
}
.step-section-title{
    margin: auto;
    /* width: 60%; */
    /* border: 3px solid #73AD21; */
    padding: 10px;
}

.section-container{
    padding: 2px;
    margin: 10px;
}
.section-border{
    border-bottom: 1px rgb(187, 187, 187) solid;
    /* width: 75%; */
    margin: auto;
    margin: 5px
}


.content-container{
    /* padding: 5px; */
    margin: 10px;
    display: flex;
    max-width: 270px;
    /* flex-wrap: wrap; */
    flex-flow: column wrap;
    flex-direction: column;
    max-height: 200px;
}
.flex-item{
    /* flex-grow: 2; */
    /* margin: 10px */
}
/** styles.css */

.smooth-image {
    transition: opacity 2s;
  }
  .image-visible {
    opacity: 1;
  }
  .image-hidden {
    opacity: 0;
  }

  .ar-btn{
      color: #ffffff;
      background-color: #000000;
      font-size: 25px;
      margin: 20px;
      font-weight: bold;
  }

  .ar-btn-desktop{
    color: #ffffff;
    background-color: #000000;
    /* font-size: 20px; */
    /* margin: auto; */
    /* top: 50%; */
    font-weight: bold;
}

.swatch{
    margin: 5px
}